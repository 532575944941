import React, { Component } from 'react';
import { connect } from "react-redux";
import queryString from 'query-string';

import './App.css';
import Signin from './signup/Signin'
import { getAuthUserInfo, setAuthUserInfo } from './GameAPI';

import * as authActions from "./store/modules/auth";

class App extends Component {
	state = {
		visibleSigninPopup: false,
    password: "",
    re_password: ""
  }

  componentDidMount() {
    this.initialize();
		this.checkUser();
  }

  componentDidUpdate(prevProps, prevState) {
		if (prevProps.logged !== this.props.logged && this.props.logged) {
			setAuthUserInfo(this.props.userInfo);
			this.redirectSiteMap();
		}
	}

	checkUser = () => {
		const userInfo = getAuthUserInfo();
		if (userInfo) {
			this.props.setUserTemp({
				id: userInfo.id,
				username: userInfo.username,
				token: userInfo.token
			});
		}

		this.props.checkUser();
	};

	redirectSiteMap = () => {
		let params = queryString.parse(window.location.search);
		let siteMap = params.siteMap? parseInt(params.siteMap) : 0;

		switch (siteMap) {
			case 0:
				window.location = 'https://eyeq.playgroundz.net/';
				break;
			case 1:
				window.location = 'https://aki.playgroundz.net/';
				break;
			case 2:
				window.location = 'https://mineral.playgroundz.net/';
				break;
			default:
				window.location = 'https://eyeq.playgroundz.net/';
		}
	}

  initialize = () => {
    const { initializeInput, initializeError } = this.props;
    initializeError();
    initializeInput();
  };

	handleOpenSignin = () => {
		this.setState({ visibleSigninPopup: true });
	}

	handleCloseSignin = () => {
		this.setState({ visibleSigninPopup: false });
		this.initialize();
	}

	renderSigninPopup = () => (
		<Signin onClose={this.handleCloseSignin} onSignup={this.handleOpenSignup}  />
	)

	handleSignin = () => {
		
	}
	
  handleSignup = () => {
    if (this.state.password !== this.state.re_password) return;
    const { register } = this.props;
    register();
  }

  handleChange(e) {
    const { name, value } = e.target;

    if(name === 'password') this.setState({ password: value });
    if(name === 're_password') this.setState({ re_password: value });

    const { changeInput } = this.props;
    changeInput({ name, value });
  }

  handleRepasswordChange(e) {
    this.setState({ re_password: e.target.value });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
        this.handleSignup();
        return;
    }
	};
	
  render() {
    return (
      <div>
				<div className="frame">
					<div className="top center">
						<img src={'/images/playgroundz_logo.svg'} alt='Playgroundz' />
						<p>The Playgroundz platform allows for gamers and developers<br/>
						to own and control their game data through a blockchain system,<br/>
						which takes advantage of Big Data and Machine Learning to specifically analyze the traits of users.</p>
					</div>
					<div className="middle">
						<div className="left vertical-center">
							<div className="horizontal-center">
								<img src={'/images/theme_image.png'} alt='Playgroundz' />
							</div>
						</div>
						<div className="right">
							<div className="signup">
								<div className="title">
									<h1>Join in the Beta Program!</h1>
								</div>
								<form>
              		<input type="email" name="username" placeholder="Email" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} maxLength="50" required />
									<input type="text" name="first_name" placeholder="Nickname" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} maxLength="50" required />
  	            	<input type="password" name="password" placeholder="Password" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} min="8" required />
    	          	<input type="password" name="re_password" placeholder="Confirm Password" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} min="8" required />
									<input className="margin-t-8" type="password" name="code" placeholder="Code" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} required />
        	      	<div onClick={this.handleSignup} className="submit">
          	      	<p>Sign Up</p>
            	  	</div>
	            	</form>
								<div className="errmsg">
									{this.props.error.triggered & !this.state.visibleSigninPopup? this.props.error.message : ''}
								</div>
								<div className="policy">
									<p>
										By signing up, you agree to our<br/>
										<a href="" target="_blank">Terms of Service</a>,&nbsp;
										<a href="" target="_blank">Privacy Policy</a>.
									</p>
								</div>
							</div>
							<div className="signin vertical-center">
								<p>Have an account? <a href='#' onClick={this.handleOpenSignin}>Sign In</a></p>
							</div>
						</div>
					</div>
					<div className="bottom">
						<div className="left">
							<a href="https://playgroundz.io" target="_blank">What is Playgroundz?</a>
						</div>
						<div className="right">
							<p>
								<a href="" target="_blank">Terms of Service</a> &nbsp;&nbsp;&nbsp; 
								<a href="" target="_blank">Privacy Policy</a> &nbsp;&nbsp;&nbsp;
								©2019 Playgroundz Foundation All rights reserved.
							</p>
						</div>
					</div>
				</div>
				{this.state.visibleSigninPopup && this.renderSigninPopup()}
			</div>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.form.username,
  password: state.auth.form.password,
  userInfo: state.auth.userInfo,
  logged: state.auth.logged,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
		checkUser: () => {
			dispatch(authActions.checkUser());
		},
		setUserTemp: ({ id, username }) => {
			dispatch(authActions.setUserTemp({ id, username }));
		},
    initializeInput: () => {
      dispatch(authActions.initializeInput());
    },
    changeInput: ({ name, value }) => {
      dispatch(authActions.changeInput({ name, value }));
    },
    initializeError: () => {
      dispatch(authActions.initializeError());
    },
    register: () => {
      dispatch(authActions.register());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
